import { useState, useEffect } from "react";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { Outlet } from "react-router-dom";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";

import {
  ListContainer,
  Item,
  KnowledgeLink,
  LinkContainer,
  IconLinkContainer,
} from "./KnowledgeBaseList.styled";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  fetchKnowledgeBase,
  deleteKnowledgeBase,
} from "../../../api/knowledgeBase";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Search } from "../../SearchComponent/SearchComponent";
import { useLocation } from "react-router-dom";
import {
  IconButton,
  TrashIconButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const KnowledgeBaseList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [isPrimary, setIsPrimary] = useState(false);
  const [isList, setIsList] = useState(false);
  const location = useLocation();
  const path = location?.pathname;

  const { data: knowledgeData, refetch } = useQuery(
    ["knowledgeData", { searchQuery, currentPage, pageLimit }],
    () => fetchKnowledgeBase({ searchQuery, pageLimit, currentPage })
  );

  const handleDelete = async (id) => {
    const response = await deleteKnowledgeBase(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return refetch();
    }
  };

  const filteredKnowledge = knowledgeData?.data?.filter((item) => {
    const titleMatch = item.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return titleMatch;
  });

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length > 2) {
        const checkPrimary = `/${parts[2]}` === "/primary-list";
        const checkList = `/${parts[2]}` === "/list";

        if (checkPrimary) {
          setIsPrimary(true);
        }

        if (checkList) {
          setIsList(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    if (filteredKnowledge) {
      const totalItems = filteredKnowledge.length;
      const calculatedTotalPages = Math.ceil(totalItems / pageLimit);
      setTotalPages(calculatedTotalPages);

      if (currentPage > calculatedTotalPages) {
        setCurrentPage(1);
      }
    }
  }, [filteredKnowledge, pageLimit, currentPage]);

  const startIndex = (Number(currentPage) - 1) * Number(pageLimit);
  const endIndex = Number(startIndex) + Number(pageLimit);
  const paginatedKnowledge = filteredKnowledge?.slice(startIndex, endIndex);

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(searchQuery);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Main>
      {knowledgeData?.data?.length > 0 ? (
        <div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Search
                inputValue={searchQuery}
                handleSearch={handleSearch}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
              />
              <PageLimitSelector
                pageLimit={pageLimit}
                onChange={handlePageLimitChange}
              />
            </div>

            <ListContainer style={{ userSelect: "none" }}>
              {paginatedKnowledge?.map((item) => (
                <Item key={item.id}>
                  <LinkContainer accessLevel={isPrimary}>
                    {isPrimary && (
                      <KnowledgeLink
                        to={`/knowledge-base/primary-list/${item.id}`}
                      >
                        {item.title}
                      </KnowledgeLink>
                    )}
                    {isList && (
                      <KnowledgeLink to={`/knowledge-base/list/${item.id}`}>
                        {item.title}
                      </KnowledgeLink>
                    )}
                  </LinkContainer>
                  {isPrimary && (
                    <IconLinkContainer>
                      <IconButton to={`/knowledge-base/change/${item.id}`}>
                        <FaEdit size={"24px"} />
                      </IconButton>
                      <TrashIconButton onClick={() => handleDelete(item.id)}>
                        <FaTrash size={"24px"} />
                      </TrashIconButton>
                    </IconLinkContainer>
                  )}
                </Item>
              ))}
            </ListContainer>

            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      ) : (
        <div>На жаль у вас поки немає даних</div>
      )}
      <Outlet />
    </Main>
  );
};
