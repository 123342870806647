import { Main } from "../../GlobalComponentsAndStyles/Main";
import { useState, useEffect } from "react";
import { Modal } from "../../Modal/Modal";
import { ClipLoader } from "react-spinners";
import {
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  SaveButton,
  AddedTimeContainer,
  AddedTimeInput,
  ScheduleMeNavContainer,
  AddScheduleContainerModal,
} from "./ScheduleMe.styled";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IconButton,
  TrashIconButton,
  Button,
  TrashButton,
  ButtonContainer,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  deleteScheduleByDay,
  fetchMySchedule,
  updateSchedule,
  createScedule,
} from "../../../api/schedule";
import { FaTrash } from "react-icons/fa";
import { MdOutlineAddCircle } from "react-icons/md";
import { calculateHoursDifference } from "../../../helpers/calculateHoursDifference";
import { formatToTime } from "../../../helpers/dateAndTime/formatToTime";
import { getColorByHoursWeek } from "../../../helpers/gradient/getColorByHoursWeek";

export const ScheduleMe = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [initialData, setInitialData] = useState({
    id: "",
    user_id: "",
    day: "",
    working_time: [],
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { data, refetch, isLoading } = useQuery(
    ["scheduleData", currentYear, currentMonth],
    () => fetchMySchedule({ year: currentYear, month: currentMonth })
  );

  const handleCancelEdit = () => {
    resetValue();
    setIsUpdate(false);
    setModalOpen(false);
  };

  const resetValue = () => {
    setInitialData({
      id: "",
      user_id: "",
      day: "",
      working_time: [],
    });
  };

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    setDaysInMonth([...Array(days).keys()].map((day) => day + 1));
  }, [currentMonth, currentYear]);

  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay();
  const correctedFirstDay = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;

  const daysWithEmptySlots = new Array(7).fill(null);

  daysInMonth.forEach((day, index) => {
    const position = correctedFirstDay - 1 + index;
    daysWithEmptySlots[position] = day;
  });

  const changeMonth = (direction) => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear((prev) => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear((prev) => prev - 1);
    } else {
      setCurrentMonth((prev) => prev + direction);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const results = [];
    while (array?.length) {
      const chunk = array.splice(0, chunkSize);

      results.push(chunk);
    }
    return results;
  };

  const chunkedDays = chunkArray(daysWithEmptySlots, 7);
  const missingCells = 7 - chunkedDays[chunkedDays?.length - 1]?.length;
  for (let i = 0; i < missingCells; i++) {
    chunkedDays[chunkedDays?.length - 1].push(null);
  }

  const handleAddWorkingTime = (arrayName, defaultItem) => {
    setInitialData({
      ...initialData,
      [arrayName]: [...initialData[arrayName], { ...defaultItem }],
    });
  };

  const handleRemoveWorkingTime = (indexToRemove) => {
    setInitialData({
      ...initialData,
      working_time: initialData?.working_time?.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const handleDayClick = async (day) => {
    const dayOfMonth = new Date(currentYear, currentMonth - 1, day).getDate();
    const findDate = data?.filter((item) => {
      const itemDay = new Date(item.day).getDate();
      return itemDay === dayOfMonth;
    });

    if (
      findDate?.length === 0 &&
      (!initialData.working_time || initialData.working_time?.length === 0)
    ) {
      resetValue();
      handleAddWorkingTime("working_time", { start: "", end: "" });
      setSelectedDay(day);
      setModalOpen(true);
    }

    if (findDate?.length > 0) {
      if (findDate[0]?.is_approve === "true") {
        return toast.warning(
          "Вибраний вами день уже оплачений, його редагувати не можна. Будь ласка, зверніться до керівництва для внесення змін."
        );
      }

      setInitialData(...findDate);
      setSelectedDay(day);
      setIsUpdate(true);
      setModalOpen(true);
    }
  };

  const handleArrayInputChange = (arrayName, index, field, value) => {
    const updatedArray = [...initialData[arrayName]];
    updatedArray[index][field] = value;

    setInitialData({
      ...initialData,
      [arrayName]: updatedArray,
    });
  };

  const handleUpdate = async () => {
    const newDate = new Date(currentYear, currentMonth - 1, selectedDay + 1);
    const formattedDate = newDate.toISOString().split("T")[0];

    const newData = {
      ...initialData,
      day: formattedDate,
    };

    if (!isUpdate) {
      const response = await createScedule(newData);

      if (response?.status === 201) {
        toast.success(response.data.message);
        resetValue();
        setIsUpdate(false);
        setModalOpen(false);
        refetch();
      }
    }

    if (isUpdate) {
      const response = await updateSchedule(newData);

      if (response?.status === 201) {
        toast.success(response.data.message);
        resetValue();
        setIsUpdate(false);
        setModalOpen(false);
        refetch();
      }
    }
  };

  const handleDelete = async () => {
    const response = await deleteScheduleByDay({ id: initialData.id });

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setModalOpen(false);
      refetch();
    }
  };

  return (
    <div>
      <Main>
        {isLoading && <ClipLoader color={"#123abc"} size={50} />}
        <ScheduleMeNavContainer style={{ width: "500px" }}>
          <Button onClick={() => changeMonth(-1)}>Минулий місяць</Button>
          <Button onClick={() => changeMonth(1)} style={{ marginLeft: "10px" }}>
            Наступний місяць
          </Button>
        </ScheduleMeNavContainer>

        {isModalOpen && (
          <Modal closeModal={() => handleCancelEdit()}>
            <AddScheduleContainerModal>
              <h2>
                День: {selectedDay >= 10 ? selectedDay : `0${selectedDay}`}.
                {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </h2>

              {initialData?.working_time?.length > 0 &&
                initialData?.working_time?.map((slot, index) => (
                  <AddedTimeContainer key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AddedTimeInput
                        type="time"
                        value={slot.start}
                        onChange={(e) =>
                          handleArrayInputChange(
                            "working_time",
                            index,
                            "start",
                            e.target.value
                          )
                        }
                      />
                      -
                      <AddedTimeInput
                        type="time"
                        value={slot.end}
                        onChange={(e) =>
                          handleArrayInputChange(
                            "working_time",
                            index,
                            "end",
                            e.target.value
                          )
                        }
                      />
                      <TrashIconButton
                        type="button"
                        onClick={() => handleRemoveWorkingTime(index)}
                        style={{ width: "30px", marginLeft: "15px" }}
                      >
                        <FaTrash size="20px" />
                      </TrashIconButton>
                    </div>
                  </AddedTimeContainer>
                ))}
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  type="button"
                  onClick={() =>
                    handleAddWorkingTime("working_time", { start: "", end: "" })
                  }
                  style={{ width: "75%" }}
                >
                  <MdOutlineAddCircle size="25px" />
                </IconButton>
              </div>
              {isUpdate ? (
                <ButtonContainer style={{ marginTop: "10px" }}>
                  <SaveButton onClick={handleUpdate}>Внести зміни</SaveButton>
                  <TrashButton onClick={handleDelete}>
                    Видалити за день
                  </TrashButton>
                </ButtonContainer>
              ) : (
                <ButtonContainer style={{ marginTop: "10px" }}>
                  <SaveButton onClick={handleUpdate}>Зберегти</SaveButton>
                  <TrashButton onClick={handleCancelEdit}>
                    Скасувати
                  </TrashButton>
                </ButtonContainer>
              )}
            </AddScheduleContainerModal>
          </Modal>
        )}

        <h1>
          Графік на місяць:{" "}
          {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}-{currentYear}
        </h1>
        <ScheduleTable>
          <thead>
            <ScheduleTr>
              {Object.values(weekDays)?.map((day, idx) => {
                return <ScheduleTh key={idx + 1}>{day}</ScheduleTh>;
              })}
              <ScheduleTh key={8}>Годин за тиждень</ScheduleTh>
            </ScheduleTr>
          </thead>

          <tbody>
            {chunkedDays?.map((week, weekIdx) => {
              let totalHoursForWeek = 0;

              return (
                <ScheduleTr key={weekIdx}>
                  {week.map((day, idx) => {
                    const dayOfMonth = new Date(
                      currentYear,
                      currentMonth - 1,
                      day
                    )?.getDate();
                    const selectedData = data?.filter((item) => {
                      const itemDay = new Date(item.day).getDate();
                      return itemDay === dayOfMonth;
                    });

                    const isApprove =
                      selectedData?.length > 0 &&
                      selectedData[0].is_approve === "true"
                        ? true
                        : false;

                    if (day && selectedData?.length > 0) {
                      const dailyHours = selectedData?.reduce((acc, item) => {
                        return (
                          acc +
                          item.working_time.reduce((hoursAcc, timeItem) => {
                            return (
                              hoursAcc +
                              calculateHoursDifference(
                                timeItem.start,
                                timeItem.end
                              )
                            );
                          }, 0)
                        );
                      }, 0);
                      totalHoursForWeek += dailyHours;
                    }

                    return (
                      <ScheduleTd
                        onClick={() => handleDayClick(day)}
                        key={idx}
                        style={{
                          backgroundColor: isApprove ? "green" : "transparent",
                          fontWeight: "bold",
                        }}
                      >
                        {day ? (
                          <>
                            {day >= 10 ? day : `0${day}`}.
                            {currentMonth >= 10
                              ? currentMonth
                              : `0${currentMonth}`}
                            <br />
                            {selectedData?.length > 0 ? (
                              selectedData.map((item, index) => (
                                <div key={index}>
                                  {item.working_time?.map((timeItem, i) => (
                                    <div key={i}>
                                      {timeItem.start} - {timeItem.end}
                                    </div>
                                  ))}
                                </div>
                              ))
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "yellow",
                                  width: "100%",
                                  fontWeight: "bold",
                                }}
                              >
                                Вихідний
                              </div>
                            )}
                          </>
                        ) : null}
                      </ScheduleTd>
                    );
                  })}

                  <ScheduleTd
                    style={{ fontWeight: "bold" }}
                    backgroundColor={getColorByHoursWeek(totalHoursForWeek)}
                  >
                    {formatToTime(totalHoursForWeek)}
                  </ScheduleTd>
                </ScheduleTr>
              );
            })}
          </tbody>
        </ScheduleTable>
      </Main>
    </div>
  );
};

const weekDays = {
  monday: "Понеділок",
  tuesday: "Вівторок",
  wednesday: "Середа",
  thursday: "Четвер",
  friday: "Пятница",
  saturday: "Субота",
  sunday: "Неділя",
};
