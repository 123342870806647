import React from "react";
import { AiOutlinePlus, AiFillFileExcel } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import {
  FileContainer,
  AddedFMInput,
  ModalContainer,
} from "./FileUpload.styled.js";
import {
  Button,
  TrashButton,
  ButtonContainer,
} from "../GlobalComponentsAndStyles/GlobalStyles.styled.js";

export const FileUpload = ({
  onFileChange,
  onUpload,
  fileName,
  isUploadLoading,
  setFile,
  setFileName,
}) => {
  return (
    <ModalContainer>
      {!fileName && !isUploadLoading && (
        <AiOutlinePlus
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="100"
        />
      )}
      {fileName && !isUploadLoading && (
        <FileContainer>
          <AiFillFileExcel
            size="100"
            color="green"
            style={{ marginRight: "10px" }}
          />
          {fileName}
        </FileContainer>
      )}
      {isUploadLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            height: "5vh",
          }}
        >
          <ClipLoader color="#3098d6" loading={isUploadLoading} size={30} />
        </div>
      )}
      <AddedFMInput type="file" accept=".xlsx, .xls" onChange={onFileChange} />
      {!isUploadLoading && (
        <ButtonContainer style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={onUpload}>Завантажити</Button>
          {fileName && (
            <TrashButton
              onClick={() => {
                setFile(null);
                setFileName(null);
              }}
            >
              Скасувати
            </TrashButton>
          )}
        </ButtonContainer>
      )}
    </ModalContainer>
  );
};
