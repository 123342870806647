import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchNewsStatistics } from "../../../api/statistic";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { v4 as uuidv4 } from "uuid";
import { formattedDateToDayMonthYearWithTime } from "../../../helpers/dateAndTime/formattedDateToDayMonthYearWithTime";
import css from "./NewsStatistics.module.css";
import { Text } from "./NewsStatistics.styled";

export const NewsStatistics = () => {
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState("createdAt");
  const [order, setOrder] = useState("DESC");
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data, isSuccess, refetch } = useQuery(
    ["fetchNewsStatistics", { sortBy, order }],
    () =>
      fetchNewsStatistics({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        sortBy,
        order,
      })
  );

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <Main>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px" }}
        >
          Оберіть період
        </Button>
      </div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>
            <Th style={{ width: "18px" }}>№</Th>
            <Th onClick={() => handleSortChange("createdAt")}>
              Дата {renderSortIcon("createdAt")}
            </Th>
            <Th
              onClick={() => handleSortChange("title")}
              style={{ width: "180px" }}
            >
              Заголовок {renderSortIcon("title")}
            </Th>
            <Th
              onClick={() => handleSortChange("description")}
              style={{ width: "350px" }}
            >
              Описання {renderSortIcon("description")}
            </Th>
            <Th onClick={() => handleSortChange("totalReaders")}>
              Прочитало {renderSortIcon("totalReaders")}
            </Th>
            <Th onClick={() => handleSortChange("totalNonReaders")}>
              Не прочитало {renderSortIcon("totalNonReaders")}
            </Th>
            <Th onClick={() => handleSortChange("totalReadersPercent")}>
              Відсоток працівників - прочитано{" "}
              {renderSortIcon("totalReadersPercent")}
            </Th>
            <Th onClick={() => handleSortChange("totalNonReadersPercent")}>
              Відсоток працівників - не прочитано{" "}
              {renderSortIcon("totalNonReadersPercent")}
            </Th>
            <Th style={{ width: "250px" }}>Список хто прочитав</Th>
            <Th style={{ width: "250px" }}>Список хто не прочитав</Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!data || data?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            {data &&
              data?.map((item, index) => {
                const key = uuidv4();
                return (
                  <Tr key={key}>
                    <Td style={{ width: "48px" }}>{index + 1}</Td>
                    <Td>
                      {" "}
                      {formattedDateToDayMonthYearWithTime(item.createdAt)}
                    </Td>
                    <Td style={{ width: "217px" }}>{item.title}</Td>
                    <Td style={{ width: "380px" }}>
                      {" "}
                      <div
                        className={css.newsContent}
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                      />
                    </Td>
                    <Td style={{ width: "135px" }}>{item.totalReaders}</Td>
                    <Td style={{ width: "135px" }}>{item.totalNonReaders}</Td>
                    <Td style={{ width: "135px" }}>
                      {item.totalReadersPercent}%
                    </Td>
                    <Td style={{ width: "135px" }}>
                      {item.totalNonReadersPercent}%
                    </Td>

                    <Td style={{ width: "280px" }}>
                      {item?.readers?.map((employee) => (
                        <Text key={employee?.id}>{employee?.fullName}</Text>
                      ))}
                    </Td>
                    <Td style={{ width: "280px" }}>
                      {item?.nonReaders?.map((employee) => (
                        <Text key={employee?.id}>{employee?.fullName}</Text>
                      ))}
                    </Td>
                  </Tr>
                );
              })}
          </tbody>
        )}
      </Table>
    </Main>
  );
};
