import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";

export const NotFound = () => {
  return (
    <div>
      <SharedLayout />
      <Main>Вибачте, ми не змогли знайти цю сторінку :(</Main>
    </div>
  );
};
