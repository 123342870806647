import React from "react";
import { useState } from "react";

export const NavContext = React.createContext();

export const NavContextProvider = ({ children }) => {
  const [menuVisible, setMenuVisible] = useState(
    sessionStorage.getItem("menuVisible") || false
  );

  const toggleMenu = () => {
    setMenuVisible((prev) => !prev);
    sessionStorage.setItem("menuVisible", menuVisible);
  };

  return (
    <NavContext.Provider value={{ menuVisible, toggleMenu }}>
      {children}
    </NavContext.Provider>
  );
};
