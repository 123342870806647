import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  Text,
  Option,
  FilterSelect,
  FilterOption,
  TrashIconButton,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
  OrderSelect,
} from "./PrimaryOrderList";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";
import {
  fetchOrderStatus,
  createOrder,
  fetchOrder,
  updateOrder,
  deleteOrder,
} from "../../../api/order";
import { fetchProduct } from "../../../api/product";
import Select from "react-select";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { fetchUsers } from "../../../api/users";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Search } from "../../SearchComponent/SearchComponent";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { addNewAmount } from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";

export const PrimaryOrderList = () => {
  const [addingNewRoute, setAddingNewRoute] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    order_id: "",
    user_id: "",
    client_full_name: "",
    client_city: "",
    department: "",
    product_1: "",
    product_2: "",
    product_3: "",
    total_amount: 0,
    order_status_id: "e3d11e3c-953c-4c20-bf94-1cf9ac1c3a11",
    createdAt: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const sortBy = "createdAt";
  const order = "DESC";
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [inputValue, setInputValue] = useState("");

  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const handleCancelEdit = () => {
    resetValue();
    setAddingNewRoute(false);
    setIsUpdate(false);
  };

  const handleEditRoute = async (item) => {
    const updatedFormData = {
      ...item,
    };

    for (let i = 1; i <= 3; i++) {
      const productId = item[`product_${i}`];
      if (productId) {
        const product = productData.data.find(
          (product) => product.id === productId
        );
        updatedFormData[`product_${i}`] = product
          ? { label: product.title, value: product.id }
          : "";
      } else {
        updatedFormData[`product_${i}`] = "";
      }
    }

    setFormData(updatedFormData);
    setIsUpdate(true);
    setAddingNewRoute(true);
  };

  const { data: productData } = useQuery(["productData"], () =>
    fetchProduct({ sortBy: "title" })
  );

  const { data: orders, refetch } = useQuery(
    [
      "orders",
      {
        currentPage,
        pageLimit,
        searchQuery,
        sortBy,
        order,
        selectedEmployee,
        selectedStatus,
      },
    ],
    () =>
      fetchOrder({
        limit: pageLimit,
        page: currentPage,
        search: searchQuery,
        sortBy,
        order,
        employee: selectedEmployee,
        status: selectedStatus,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  const { data: orderStatusData } = useQuery("orderStatus", fetchOrderStatus);

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      sortBy: "full_name",
      order: "ASC",
      isBlocked: "false",
      jobTitle: "eab7c78f-1559-4bed-8a2a-67d97240f6c6",
    })
  );

  const handleDeleteRoute = async (id) => {
    const response = await deleteOrder(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      handleCancelEdit();
      refetch();
    }
  };

  const resetValue = () => {
    setFormData({
      id: "",
      order_id: "",
      user_id: "",
      client_full_name: "",
      client_city: "",
      department: "",
      product_1: "",
      product_2: "",
      product_3: "",
      total_amount: 0,
      order_status_id: "e3d11e3c-953c-4c20-bf94-1cf9ac1c3a11",
    });
  };

  const handleUpdate = async () => {
    const response = await updateOrder(formData);

    if (response?.status === 201) {
      const newData = {
        order_id: formData.id,
        order_status_id: formData.order_status_id,
      };
      const amountResponse = await addNewAmount(newData);

      if (amountResponse?.status === 200 || amountResponse?.status === 201) {
        toast.success(response.data.message);
        toast.success(amountResponse.data.message);
        resetValue();
        setIsUpdate(false);
        setAddingNewRoute(false);
        refetch();
      }
    }
  };

  const handleSubmit = async () => {
    const response = await createOrder(formData);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const calculateTotalAmount = (newData) => {
    let totalAmount = 0;

    if (
      newData.product_1 &&
      newData.product_1.value &&
      newData.product_1.value !== "0"
    ) {
      const product1 = productData.data.find(
        (product) => product.id === newData.product_1.value
      );
      totalAmount += product1?.price + 500 || 0;
    }

    if (
      newData.product_2 &&
      newData.product_2.value &&
      newData.product_2.value !== "0"
    ) {
      const product2 = productData.data.find(
        (product) => product.id === newData.product_2.value
      );
      totalAmount += product2?.price + 500 || 0;
    }

    if (
      newData.product_3 &&
      newData.product_3.value &&
      newData.product_3.value !== "0"
    ) {
      const product3 = productData.data.find(
        (product) => product.id === newData.product_3.value
      );
      totalAmount += product3?.price + 500 || 0;
    }

    return totalAmount;
  };

  const handleProductChange = async (selectedOption, productNumber) => {
    const updatedFormData = {
      ...formData,
      [`product_${productNumber}`]: selectedOption,
    };
    const totalAmount = await calculateTotalAmount(updatedFormData);
    setFormData({
      ...updatedFormData,
      total_amount: totalAmount,
    });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (orders) {
      setTotalPages(orders?.totalPages);
    }
  }, [orders, pageLimit]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <div style={{ margin: "20px" }}>
        <Search
          handleSearch={handleSearch}
          handleInputChange={handleInputChange}
          handleKeyPress={handleKeyPress}
          inputValue={inputValue}
        />
        <div
          style={{
            margin: "20px 0",
            display: "flex",
            alignItems: "center",
            heigh: "25px",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{ marginRight: "10px" }}
          >{`${formattedStartDate} - ${formattedEndDate}`}</div>

          <Button
            onClick={() => setIsModalDateOpen(true)}
            style={{ width: "200px", heigh: "15px" }}
          >
            Оберіть період
          </Button>

          <label style={{ marginLeft: "10px" }}>Оберіть оператора: </label>
          <FilterSelect
            value={selectedEmployee}
            onChange={(e) => {
              setSelectedEmployee(e.target.value);
              setCurrentPage(1);
            }}
            style={{ marginRight: "5px", width: "200px" }}
          >
            <Option value="">Виберіть оператора</Option>
            {usersData?.usersData?.map((user) => (
              <FilterOption key={user?.id} value={user?.id}>
                {user?.full_name}
              </FilterOption>
            ))}
          </FilterSelect>

          <label style={{ marginLeft: "10px" }}>Оберіть статус: </label>
          <FilterSelect
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setCurrentPage(1);
            }}
            style={{ marginRight: "5px", width: "200px" }}
          >
            <Option value="">Виберіть статус</Option>
            {orderStatusData?.map((status) => (
              <FilterOption key={status?.id} value={status?.id}>
                {status?.title}
              </FilterOption>
            ))}
          </FilterSelect>

          <PageLimitSelector
            pageLimit={pageLimit}
            onChange={handlePageLimitChange}
          />
        </div>
      </div>

      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}
      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "80px" }}>Дата</RouteTh>
            <RouteTh>ПІБ оператора</RouteTh>
            <RouteTh style={{ width: "80px" }}>Номер замовленя</RouteTh>
            <RouteTh>ПІБ клієнта</RouteTh>
            <RouteTh style={{ width: "80px" }}>Місто</RouteTh>
            <RouteTh style={{ width: "80px" }}>Віділеня НП</RouteTh>
            <RouteTh RouteTd style={{ width: "250px" }}>
              Товари
            </RouteTh>
            <RouteTh style={{ width: "80px" }}>Сума замовленя</RouteTh>
            <RouteTh>Коментар</RouteTh>
            <RouteTh style={{ width: "90px" }}>Статус</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          <Tr>
            {addingNewRoute && !isUpdated ? (
              <>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd style={{ width: "102px" }}>
                  <Input
                    type="text"
                    value={formData.order_id}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const updatedFormData = {
                        ...formData,
                        order_id: sanitizedValue,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd>
                  <Input
                    type="text"
                    value={formData.client_full_name}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        client_full_name: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "102px" }}>
                  <Input
                    type="text"
                    value={formData.client_city}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        client_city: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "102px" }}>
                  <Input
                    type="text"
                    value={formData.department}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        department: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "270px" }}>
                  {[1, 2, 3].map((index) => (
                    <Select
                      key={index}
                      value={{
                        label:
                          formData[`product_${index}`]?.label ||
                          "Виберіть товар",
                        value: formData[`product_${index}`]?.value || "0",
                      }}
                      onChange={(selectedOption) =>
                        handleProductChange(selectedOption, index)
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: "3",
                          width: "100%",
                        }),
                      }}
                      options={[
                        { label: "Виберіть товар", value: "0" },
                        ...(productData?.data || []).map((product) => ({
                          label: product.title,
                          value: product.id,
                        })),
                      ]}
                      placeholder="Виберіть товар"
                    />
                  ))}
                </RouteTd>

                <RouteTd style={{ width: "102px" }}>
                  <Input
                    type="text"
                    value={`${formData.total_amount} грн`}
                    disabled
                  />
                </RouteTd>

                <RouteTd>
                  <Input
                    type="text"
                    value={formData.comment}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        comment: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "112px" }}>
                  <OrderSelect
                    value={formData?.order_status_id}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        order_status_id: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  >
                    <Option value="">Виберіть статус</Option>
                    {orderStatusData?.map((status) => (
                      <Option key={status?.id} value={status?.id}>
                        {status?.title}
                      </Option>
                    ))}
                  </OrderSelect>
                </RouteTd>
                <Td>
                  <IconLinkContainer>
                    <IconButton onClick={handleSubmit}>
                      <FaSave size="20px" />
                    </IconButton>
                    <TrashIconButton onClick={() => handleCancelEdit()}>
                      <MdCancel size="25px" />
                    </TrashIconButton>
                  </IconLinkContainer>
                </Td>
              </>
            ) : (
              <>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd style={{ width: "270px" }}></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd style={{ width: "112px" }}></RouteTd>
                <Td>
                  <IconButton
                    onClick={() => setAddingNewRoute(true)}
                    style={{ width: "75%" }}
                  >
                    <MdOutlineAddCircle size="25px" />
                  </IconButton>
                </Td>
              </>
            )}
          </Tr>
          {orders?.ordersData?.length > 0 && (
            <React.Fragment>
              {orders?.ordersData?.map((item) => (
                <Tr key={item?.id}>
                  {addingNewRoute && isUpdated && formData?.id === item?.id ? (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </RouteTd>
                      <RouteTd>
                        <OrderSelect
                          value={formData?.user_id}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              user_id: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        >
                          <Option value="">Виберіть оператора</Option>
                          {usersData?.usersData?.map((employee) => (
                            <Option key={employee?.id} value={employee?.id}>
                              {employee?.full_name}
                            </Option>
                          ))}
                        </OrderSelect>
                      </RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="number"
                          value={formData.order_id}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              order_id: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd>
                        <Input
                          type="text"
                          value={formData.client_full_name}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              client_full_name: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="text"
                          value={formData.client_city}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              client_city: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="text"
                          value={formData.department}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              department: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "270px" }}>
                        {[1, 2, 3].map((index) => (
                          <Select
                            key={index}
                            value={{
                              label:
                                formData[`product_${index}`]?.label ||
                                "Виберіть товар",
                              value: formData[`product_${index}`]?.value || "0",
                            }}
                            onChange={(selectedOption) =>
                              handleProductChange(selectedOption, index)
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "100%",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: "3",
                                width: "100%",
                              }),
                            }}
                            options={[
                              { label: "Виберіть товар", value: "0" },
                              ...(productData?.data || []).map((product) => ({
                                label: product.title,
                                value: product.id,
                              })),
                            ]}
                            placeholder="Виберіть товар"
                          />
                        ))}
                      </RouteTd>

                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="text"
                          value={`${formData.total_amount} грн`}
                          disabled
                        />
                      </RouteTd>

                      <RouteTd>
                        <Input
                          type="text"
                          value={formData.comment}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              comment: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "112px" }}>
                        <OrderSelect
                          value={formData?.order_status_id}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              order_status_id: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        >
                          <Option value="">Виберіть статус</Option>
                          {orderStatusData?.map((status) => (
                            <Option key={status?.id} value={status?.id}>
                              {status?.title}
                            </Option>
                          ))}
                        </OrderSelect>
                      </RouteTd>

                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </RouteTd>
                      <RouteTd>{item.full_name}</RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {item.order_id}
                      </RouteTd>
                      <RouteTd>{item?.client_full_name}</RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {item?.client_city}
                      </RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {item?.department}
                      </RouteTd>
                      <RouteTd style={{ width: "270px" }}>
                        {productData?.data?.map((product, index) => (
                          <React.Fragment key={product.id + index}>
                            <Text>
                              {item.product_1 === product.id && product.title}
                            </Text>
                            <Text>
                              {item.product_2 === product.id && product.title}
                            </Text>
                            <Text>
                              {item.product_3 === product.id && product.title}
                            </Text>
                          </React.Fragment>
                        ))}
                      </RouteTd>

                      <RouteTd style={{ width: "102px" }}>
                        {item?.total_amount} грн
                      </RouteTd>
                      <RouteTd>{item?.comment}</RouteTd>
                      <RouteTd style={{ width: "112px" }}>
                        {orderStatusData?.map((status) => (
                          <React.Fragment key={status.id}>
                            <Text>
                              {item.order_status_id === status.id &&
                                status.title}
                            </Text>
                          </React.Fragment>
                        ))}
                      </RouteTd>
                      <Td>
                        {formData?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton onClick={() => handleEditRoute(item)}>
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => handleDeleteRoute(item.id)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
