import styled from '@emotion/styled';
import {
    Td,
    Th,
    Select,
  } from "../GlobalComponentsAndStyles/GlobalStyles.styled";

export const RouteTh = styled(Th)`
width: 146px;
`

export const RouteTd = styled(Td)`
width: 168px;
`

export const IconLinkContainer = styled.div`
display: flex;
width: 100%;
`

export const ContactSelect = styled(Select)`
`