import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { RouteTh, RouteTd } from "./BalanceMe.styled";
import { fetchBalanceMe } from "../../../api/balance";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Pagination } from "../../Pagination/Pagination";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { Main } from "../../GlobalComponentsAndStyles/Main";

export const BalanceMe = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data, refetch } = useQuery(
    [
      "balanceMe",
      {
        currentPage,
        pageLimit,
      },
    ],
    () =>
      fetchBalanceMe({
        limit: pageLimit,
        page: currentPage,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <Main>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}
      <div style={{ margin: "20px", display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "150px", marginLeft: "10px" }}
        >
          Оберіть період
        </Button>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>

      <Table style={{ marginBottom: "20px" }}>
        <Thead>
          <HeadTr>
            <RouteTh>За погодину оплату</RouteTh>
            <RouteTh>Бонус за замовленя</RouteTh>
            <RouteTh>Всього</RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.sums && (
            <React.Fragment>
              <Tr>
                <>
                  <RouteTd>{data?.sums?.schedule_sum} грн</RouteTd>

                  <RouteTd>{data?.sums?.order_sum} грн</RouteTd>
                  <RouteTd>{data?.sums?.total_sum} грн</RouteTd>
                </>
              </Tr>
            </React.Fragment>
          )}
        </tbody>
      </Table>

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh>Дата нарахуваня</RouteTh>
            <RouteTh>Сума</RouteTh>
            <RouteTh style={{ width: "582px" }}>Коментар</RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.balanceData?.length > 0 && (
            <React.Fragment>
              {data?.balanceData?.map((item) => (
                <Tr key={item?.id}>
                  <>
                    <RouteTd>
                      {formattedDateToDayMonthYear(item.updateAt)}
                    </RouteTd>

                    <RouteTd>{item?.amount} грн</RouteTd>
                    <RouteTd style={{ width: "604px" }}>
                      {item?.comment}
                    </RouteTd>
                  </>
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
