export const translateLabel = (label, locale) => {
    const labelsTranslation = {
      uk: {
        Today: 'Сьогодні',
        Yesterday: 'Вчора',
        'This Week': 'Поточний тиждень',
        'Last Week': 'Останній тиждень',
        'This Month': 'Поточний місяць',
        'Last Month': 'Останній місяць',
        'days up to today': 'днів до сьогодні',
        'days starting today': 'днів, починаючи від сьогодні',
      },
    };
  
    return labelsTranslation[locale]?.[label] || label;
  };
  