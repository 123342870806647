import styled from "@emotion/styled";
import {
  Button,
  Table,
  Tr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const ScheduleTable = styled(Table)`
  margin: 1em 0;
  max-height: 100%;
  overflow: auto;
  border-collapse: collapse;
  width: 100%;
`;

export const ScheduleTr = styled(Tr)`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScheduleTd = styled(Td)`
  flex: 1;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 112px;
  border-bottom: 1px solid #d9e4e6;
  border-right: 1px solid #d9e4e6;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
    background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};

  &:last-child {
    border-right: none;
  }
`;

export const ScheduleTh = styled(Th)`
  flex: 1;
  height: 30px;
`;

export const SaveButton = styled(Button)`
  width: 45%;
  margin-left: auto;
  margin-right: auto;
`;

export const AddedTimeContainer = styled.div`
  width: 500px;
  min-height: 60px;
`;

export const AddedTimeInput = styled.input`
  appearance: none;
  width: 120px;
  height: 30px;
  padding: 5px;
  background-color: #eaf3f3;
  border: 2px solid #2f303a;
  font-size: 18px;
  margin-left: 10px;
  border-radius: 10px;

  cursor: pointer;
`;

export const ScheduleMeNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddScheduleContainerModal = styled.div`
  width: 500px;
  min-height: 300px;
  text-align: center;
`;
