import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const ProductSection = styled.section`
  padding-bottom: 60px;

  @media screen and (min-width: 1200px) {
    padding-bottom: 94px;
  }
`;

export const ProductNavigationList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  margin-bottom: 40px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: 768px) {
    justify-content: center;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 50px;
  }
`;

export const ProductNavigationItem = styled.li`
  margin-right: 8px;
  margin-bottom: 15px;

  &:last-child {
    margin-right: 0px;
  }

  &::nth-of-type(n + 4) {
    margin-bottom: 0px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
`;
export const ProductNavigationButton = styled.button`
  border: transparent;
  font-weight: 700;
  background-color: #2f303a;
  color: #f5f7fa;
  font-size: 18px;
  letter-spacing: 1.33;
  text-align: center;
  border-radius: 4px;
  padding: 6px 22px;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    filter 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus,
  &:checked,
  &:active {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    background-color: green;
    transition: background-color 0.4s;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0px;
  }
`;

export const ActiveCategoryButton = styled(ProductNavigationButton)`
  background-color: green;
`;

export const ProductCardList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-bottom: -30px;
`;

export const ProductCardItem = styled.li`
  margin-bottom: 30px;
  cursor: pointer;
  transition: drop-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    filter 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &:hover {
    transform: translateY(0);
  }

  @media screen and (max-width: 768px) {
    &:last-child {
      margin-right: 0px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-right: 30px;
    flex-basis: calc(100% / 2 - 30px);
  }

  @media screen and (min-width: 1200px) {
    flex-basis: calc(100% / 3 - 30px);
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #ffffff;
  width: 100%;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0px;

  @media screen and (min-width: 480px) {
    width: 450px;
    height: 294px;
  }

  @media screen and (min-width: 768px) {
    width: 354px;
    height: 294px;
  }

  @media screen and (min-width: 1200px) {
    width: 370px;
    height: 370px;
  }
`;

export const ProductTextContainer = styled.div`
width: 100%;
padding: 20px 24px;
background: #ffffff;
border: 1px solid #eeeeee;
box-sizing: border-box;
display: inline-flex;

@media screen and (min-width: 768px) {
    min-height: 210px;
  }
}
@media screen and (min-width: 1200px) {
    min-height: 111.91px;
}
`;

export const ProductTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 2;
  letter-spacing: 0.06em;
  color: #212121;
  margin-bottom: 4px;
`;

export const ProductLabel = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.87;
  letter-spacing: 0.03em;
`;

export const ProductPrice = styled(ProductLabel)`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

export const ProductLink = styled(Link)`
  text-decoration: none;
  color: #212121;
`;
