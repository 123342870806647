import {
    SearchContainer,
    SearchInput,
    SearchButton,
  } from "./SearchComponent.styled";
  import { AiOutlineSearch } from "react-icons/ai";

export const Search = ({handleSearch,inputValue, handleInputChange, handleKeyPress }) => {
    
    return ( <SearchContainer onSubmit={handleSearch}>
      <SearchInput
        type="text"
        placeholder="Введіть ваш запит та натисніть на лупу"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
      />
      <SearchButton type="submit" onClick={handleSearch}>
        <AiOutlineSearch size="24px" />
      </SearchButton>
    </SearchContainer>
    ) 
  }