import styled from "@emotion/styled";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import {
  Button,
  FilterSelect,
  TrashButton,
  FilterOption,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const BlockedButton = styled(Button)`
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const BlockedButtonRed = styled(TrashButton)`
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const IsBlockedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const UserFaTimesCircle = styled(FaTimesCircle)`
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
`;

export const UserFaMinusCircle = styled(FaCheckCircle)`
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
`;

export const UserFilterSelect = styled(FilterSelect)`
  width: 180px;
`;

export const UserFilterOption = styled(FilterOption)`
  width: 180px;
`;
