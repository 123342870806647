import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  Option,
  FilterSelect,
  FilterOption,
  TrashIconButton,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
  OrderSelect,
} from "./PrimaryBalanceList";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { fetchUsers } from "../../../api/users";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { fetchBalanceAll, updateBalance } from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";

export const PrimaryBalanceList = () => {
  const [addingNewRoute, setAddingNewRoute] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    amount: "",
    user_id: "",
    comment: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const handleCancelEdit = () => {
    resetValue();
    setAddingNewRoute(false);
    setIsUpdate(false);
  };

  const handleEditRoute = async (item, isDelete) => {
    const updatedFormData = {
      ...item,
      status_message_id: isDelete ? 4 : item.status_message_id,
    };

    if (isDelete) {
      await handleUpdate(updatedFormData, isDelete);
      return;
    }

    setFormData(updatedFormData);
    setIsUpdate(true);
    setAddingNewRoute(true);
  };

  const { data, refetch } = useQuery(
    [
      "balance",
      {
        currentPage,
        pageLimit,
        selectedEmployee,
      },
    ],
    () =>
      fetchBalanceAll({
        limit: pageLimit,
        page: currentPage,
        employee: selectedEmployee,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      sortBy: "full_name",
      order: "ASC",
      isBlocked: "false",
      jobTitle: "eab7c78f-1559-4bed-8a2a-67d97240f6c6",
    })
  );

  const resetValue = () => {
    setFormData({
      id: "",
      amount: "",
      user_id: "",
      comment: "",
    });
  };

  const handleUpdate = async (data = formData) => {
    const response = await updateBalance(data);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <div
        style={{
          margin: "20px",
          display: "flex",
          alignItems: "center",
          heigh: "25px",
          flexWrap: "wrap",
        }}
      >
        <div>{`${formattedStartDate} - ${formattedEndDate}`}</div>

        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px", heigh: "15px", marginLeft: "10px" }}
        >
          Оберіть період
        </Button>

        <label style={{ marginLeft: "10px" }}>Оберіть оператора: </label>
        <FilterSelect
          value={selectedEmployee}
          onChange={(e) => {
            setSelectedEmployee(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть оператора</Option>
          {usersData?.usersData?.map((user) => (
            <FilterOption key={user?.id} value={user?.id}>
              {user?.full_name}
            </FilterOption>
          ))}
        </FilterSelect>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>

      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "80px" }}>Дата нарахуваня</RouteTh>
            <RouteTh>ПІБ оператора</RouteTh>
            <RouteTh style={{ width: "80px" }}>Сума</RouteTh>
            <RouteTh style={{ width: "582px" }}>Коментар</RouteTh>
            <RouteTh>Статус повідмоленя</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.balanceData?.length > 0 && (
            <React.Fragment>
              {data?.balanceData?.map((item) => (
                <Tr key={item?.id}>
                  {addingNewRoute && isUpdated && formData?.id === item?.id ? (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.updateAt)}
                      </RouteTd>
                      <RouteTd>
                        <OrderSelect
                          value={formData?.user_id}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              user_id: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        >
                          <Option value="">Виберіть оператора</Option>
                          {usersData?.usersData?.map((employee) => (
                            <Option key={employee?.id} value={employee?.id}>
                              {employee?.full_name}
                            </Option>
                          ))}
                        </OrderSelect>
                      </RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="number"
                          value={formData.amount}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              amount: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "604px" }}>
                        <Input
                          type="text"
                          value={formData.comment}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              comment: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd>{formData.message_status_title}</RouteTd>

                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.updateAt)}
                      </RouteTd>
                      <RouteTd>{item.full_name}</RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {item?.amount} грн
                      </RouteTd>

                      <RouteTd style={{ width: "604px" }}>
                        {item?.comment}
                      </RouteTd>
                      <RouteTd>{item?.message_status_title}</RouteTd>
                      <Td>
                        {formData?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton
                              onClick={() => handleEditRoute(item, false)}
                            >
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => handleEditRoute(item, true)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
