import styled from "@emotion/styled";

export const FileContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  margin: 0;
`;

export const AddedFMInput = styled.input`
  appearance: none;
  padding: 12px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 20px;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  border: 1px solid #000;
  margin: 20px 20px 80px 20px;
`;
