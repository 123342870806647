import styled from '@emotion/styled';

export const Wrapper = styled.div`
  text-align: center;
  font-size: 40px;
  color: white;
  font-family: 'Wix Madefor Display', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;