import { createContext} from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
const token = localStorage.getItem("token");

  const saveToken = (token) => {
    localStorage.setItem("token", token); 
  };

  const clearToken = () => {
    localStorage.setItem("token", ''); 
  };

  return (
    <UserContext.Provider value={{ token, saveToken, clearToken }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;