import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchProductOrderStatistics } from "../../../api/statistic";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import {
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { v4 as uuidv4 } from "uuid";

export const ProductOrderStatistics = () => {
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState("title");
  const [order, setOrder] = useState("ASC");
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);


  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data, isSuccess, refetch } = useQuery(
    ["fetchProductOrderStatistics", {sortBy, order }],
    () =>
        fetchProductOrderStatistics({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        sortBy, 
        order 
      })
  );

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <Main>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button onClick={() => setIsModalDateOpen(true)} style={{width: '200px'}}>Оберіть період</Button>
      </div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>         
          <Th style={{width: '18px'}}>
              №
            </Th>                                                                         
            <Th onClick={() => handleSortChange("title")} style={{width: '250px'}}>
              Назва офферу {renderSortIcon("title")}
            </Th>
            <Th onClick={() => handleSortChange("total_orders")}>
              Всього замовлень {renderSortIcon("total_orders")}
            </Th>
            <Th onClick={() => handleSortChange("bought_out_orders")}>
              Викуплених замовлень {renderSortIcon("bought_out_orders")}
            </Th>
            <Th onClick={() => handleSortChange("delievery_order")}>
              Замовлень в принятих {renderSortIcon("delievery_order")}
            </Th>
            <Th onClick={() => handleSortChange("not_bought_out_orders")}>
              Не викуплених замовлень {renderSortIcon("not_bought_out_orders")}
            </Th>
            <Th onClick={() => handleSortChange("bought_out_percentage")}>
              Відсоток викупів від всіх замовлень {renderSortIcon("bought_out_percentage")}
            </Th>
            <Th onClick={() => handleSortChange("not_bought_out_percentage")}>
              Відсоток не викупів від всіх замовлень {renderSortIcon("not_bought_out_percentage")}
            </Th>
            <Th onClick={() => handleSortChange("relevant_amount_percentage")}>
              Відсоток викуплених від викуплених/невикуплених {renderSortIcon("relevant_amount_percentage")}
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!data || data?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            {data && data?.map((item, index) => {
              const key = uuidv4();
              return (
                <Tr key={key}>
                  <Td style={{width: '40px'}}>{index + 1}</Td>
                  <Td  style={{width: '272px'}}>{item.title}</Td>
                  <Td>{item.total_orders}</Td>
                  <Td>{item.bought_out_orders}</Td>
                  <Td>{item.delievery_order}</Td>
                  <Td>{item.not_bought_out_orders}</Td>
                  <Td>{item.bought_out_percentage}%</Td>
                  <Td>{item.not_bought_out_percentage}%</Td>
                  <Td>{item.relevant_amount_percentage}%</Td>
                </Tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </Main>
  );
};
