import { HiArrowLeft } from 'react-icons/hi';
import { StyledLink } from './BackLink.styled';
import { UserContext } from '../Context/UserContext';
import { useContext } from 'react';

export const BackLink = ({ to, children }) => {
  const { language } = useContext(UserContext);

  return (
    <StyledLink to={to}>
      <HiArrowLeft size="24" />
      {language === 'russian' ? 'К списку' : "До списка"}
    </StyledLink>
  );
};
