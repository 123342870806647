import styled from "@emotion/styled";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconLinkContainer = styled.div`
  display: flex;
  width: 20%;
  height: 34px;
`;

export const ImageAndVideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #ffffff;
  width: 560px;
  height: 560px;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0px;
`;

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #ffffff;
  width: 560px;
  height: 560px;
  margin-left: 30px;
`;

export const ProductText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 1.87;
  letter-spacing: 0.03em;
  font-weight: 700;
`;
