import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";

export const TopBarLogoutButton = styled.button`
  cursor: pointer;
  box-shadow: none;
  width: 50px;
  height: 50px;
  color: #3098d6;
  border: none;
  background-color: inherit;
`;

export const SharedLayoutContainer = styled.header`
  display: flex;
  height: 60px;
  width: 100%;
  background-color: #2f303a;
  padding: 15px 15px 0 15px;
`;
export const ImageContainer = styled.div`
  width: 150px;
  height: 50px;
`;

export const NavList = styled.nav`
  background-color: #48495c;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  height: 100vh;
  position: absolute;
  width: 250px;
  left: 0;
  top: 0;
  z-index: 999;
  margin-left: ${({ isVisible }) => (isVisible ? "0" : "-300px")};
  transition: margin-left 0.3s ease;
  overflow-y: auto;
  position: fixed;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #7a7a3c;
  }
  &::-webkit-scrollbar-thumb {
    background: #b3b350;
  }
`;

export const NavigationLink = styled(NavLink)`
  color: #ffffff;
  transition: background-color 0.2s;
  display: block;
  text-decoration: none;
  background-color: inherit;
  font-size: 20px;
  font-weight: 700;
  padding: 3px 10px;

  &:hover,
  &:focus,
  &.active {
    background-color: #eeee40;
    color: #000000;
  }
`;

export const TopBarLogoutContainer = styled.div`
  width: 10%;
  margin-left: 80px;
`;

export const ExitDoor = styled(FaPowerOff)`
  color: #ffffff;

  &:hover,
  &:focus {
    color: #eeee40;
  }
`;

export const SecondaryRouteContainer = styled.ul`
  display: block;
  background-color: #262633;
  margin: 0px;
  padding: 0;
  list-style: none;
  width: 250px;
  z-index: 10;
`;

export const SecondaryItem = styled.li`
  color: #ffffff;
  width: 250px;
  transition: background-color 0.2s;
  display: block;
  padding: 0;
  text-decoration: none;
  background-color: inherit;
  font-size: 20px;
  font-weight: 700;

  &:hover,
  &:focus,
  &.active {
    background-color: #eeee40;
    color: #000000;
  }
`;

export const SecondaryNavigationLink = styled(NavigationLink)`
  width: 100% - 30px;
  padding: 3px 10px 3px 20px;
`;

export const SharedLayoutListItem = styled.li`
  padding: 0;
  width: 250px;
  font-size: 20px;
`;

export const TopContainer = styled.div`
  margin-left: ${({ isVisible }) => (isVisible ? "275px" : "25px")};
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: margin-left 0.3s ease;
`;

export const UnreadText = styled.span`
  color: #ffffff;
  background-color: #cc0000;
  border-radius: 50%;
  width: ${(props) => (props.newsLength > 99 ? "45px" : "30px")};
  height: ${(props) => (props.newsLength > 99 ? "30px" : "30px")};
  display: inline-block;
  text-align: center;
  margin-left: 10px;
`;
