import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  NewsSmallTh,
  NewsLargeTh,
  NewsSmallTd,
  NewsLargeTd,
  NewsButton,
} from "./SecondaryNewsList.styled";
import { approveNews, fetchMyNews } from "../../../api/news";
import { formattedDateToDayMonthYearWithTime } from "../../../helpers/dateAndTime/formattedDateToDayMonthYearWithTime";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Search } from "../../SearchComponent/SearchComponent";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import css from "../PrimaryNewsList/PrimaryNewsList.module.css";
import { EmojiDisplay } from "../../EmojiDisplay/EmojiDisplay";

export const SecondaryNewsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const sortBy = "createdAt";
  const order = "DESC";

  const [inputValue, setInputValue] = useState("");

  const { data: news, refetch } = useQuery(
    [
      "news",
      {
        currentPage,
        pageLimit,
        searchQuery,
        sortBy,
        order,
      },
    ],
    () =>
      fetchMyNews({
        limit: pageLimit,
        page: currentPage,
        search: searchQuery,
        sortBy,
        order,
      })
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (news) {
      setTotalPages(news?.totalPages);
    }
  }, [news, pageLimit]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleApproveNews = async (item) => {
    const response = await approveNews({ news_id: item.id });

    if (response?.status === 201) {
      refetch();
    }
  };

  return (
    <Main>
      <div style={{ margin: "20px", display: "flex", alignItems: "center" }}>
        <Search
          handleSearch={handleSearch}
          handleInputChange={handleInputChange}
          handleKeyPress={handleKeyPress}
          inputValue={inputValue}
        />

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
          style={{ marginLeft: "20px" }}
        />
      </div>

      <Table>
        <Thead>
          <HeadTr>
            <NewsSmallTh>Дата</NewsSmallTh>
            <NewsSmallTh>Заголовок</NewsSmallTh>
            <NewsLargeTh>Описання</NewsLargeTh>
            <NewsSmallTh>Панeль управліня</NewsSmallTh>
          </HeadTr>
        </Thead>
        <tbody>
          {news?.data?.length > 0 ? (
            <>
              {news?.data?.map((item) => (
                <Tr key={item?.id}>
                  <NewsSmallTd>
                    {formattedDateToDayMonthYearWithTime(item.createdAt)}
                  </NewsSmallTd>
                  <NewsSmallTd>{item.title}</NewsSmallTd>
                  <NewsLargeTd>
                    {" "}
                    <div
                      style={{ userSelect: "none" }}
                      className={css.newsContent}
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </NewsLargeTd>

                  <NewsSmallTd>
                    {item.is_approve === "true" ? (
                      <EmojiDisplay emojiId="1f44d" />
                    ) : (
                      <NewsButton onClick={() => handleApproveNews(item)}>
                        <EmojiDisplay emojiId="1f614" />
                      </NewsButton>
                    )}
                  </NewsSmallTd>
                </Tr>
              ))}
            </>
          ) : (
            <>
              <Tr>
                <Td style={{ width: "100%" }}>Нажаль новин поки що немає</Td>
              </Tr>
            </>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
