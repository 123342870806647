import styled from "@emotion/styled";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconLinkContainer = styled.div`
  display: flex;
  width: 20%;
  height: 34px;
`;
