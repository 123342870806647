import styled from "@emotion/styled";

export const PaginationList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  font-weight: 500;
  margin-bottom: 50px;
`;

export const PaginationItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #f5f7fa;
  border: 1px solid #000000;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 500;
    background-position: 50%;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
    background-color: #2f303a;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &:hover,
  &:focus {
    background-color: green;
    color: #ffffff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &.active {
    background-color: green;
    color: #ffffff;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
      0 2px 1px -1px rgb(0 0 0 / 12%);
    font-weight: bold;
  }

  @media screen and (max-width: 767px) {
    width: 35px;
    height: 35px;
  }

  @media screen and (min-width: 767px) {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  
  @media screen and (min-width: 767px) {
    &:not(:last-child) {
      margin-right: 12px;
    }
`;

export const PaginationArrowLeft = styled(PaginationItem)``;

export const PaginationArrowRight = styled(PaginationItem)``;
