import * as Yup from "yup";

export const LoginSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Електронна пошта обовязкове поле")
      .min(10, "Електронна пошта повинна бути не менше 10 символів")
      .max(63, "Електронна пошта повинна бути не більше 63 символів")
      .email("Будь ласка введіть коректну Електронну пошту")
      .matches(
        /^[a-zA-Z0-9_]+([.-]?[a-zA-Z0-9_]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
        "Будь ласка введіть коректну Електронну пошту"
      ),
    password: Yup.string()
      .trim()
      .required("Пароль обовязкове поле")
      .min(7, "Пароль повинен бути не менше 7 символів")
      .max(32, "Пароль повинен бути не більше 32 символів")
      .matches(/^\S*$/, "Пароль не повинен містити пробілів")
      .matches(
        /[a-z]/,
        "Пароль повинен містити хоча б одну малу англійську літеру"
      )
      .matches(
        /[A-Z]/,
        "Пароль повинен містити хоча б одну велику англійську літеру"
      )
      .matches(
        /^[a-zA-Z0-9!@#$%^&*()_+,-./:;<=>?@[\]^_`{|}~]+$/,
        "Пароль може містити лише англійські літери та спеціальні символи."
      ),
  });
};
