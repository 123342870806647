import styled from "@emotion/styled";

export const PrimaryList = styled.ul`
  position: fixed;
  top: 95px;
  right: 0;
  width: 28%;
  font-size: 20px;
  max-height: 85vh; /* Задаємо максимальну висоту списку */
  overflow-y: auto; /* Додаємо вертикальну прокрутку при необхідності */
`;

export const SecondaryList = styled.ul``;

export const PimaryItem = styled.li``;

export const SecondaryItem = styled.li``;

export const ScriptNavLink = styled.a`
  color: #000000;
`;
