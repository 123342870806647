import styled from "@emotion/styled";

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 999;
`;

export const Button = styled.button`
  background-color: #2f303a;
  border: 2px solid #eeee40;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: green;
    border: 2px solid #2f303a;
    transition: background-color 0.4s;
  }
`;
