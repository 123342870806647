import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { Outlet } from "react-router-dom";
import { ScrollToTopButton } from "../../components/ScrollToTopButton/ScrollToTopButton";

export const UserPage = () => {
  return (
    <div>
      <SharedLayout />
      <ScrollToTopButton />
      <Outlet />
    </div>
  );
};
