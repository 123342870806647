import { makeRequestSQL, clearAuthHeader  } from './api';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchUsers = async ({ id, jobTitle, sortBy, order, isBlocked, search, page, limit }) => {
  try {
    const response = await makeRequestSQL.get(
      '/user/',
      {
        params: {
          id,
          jobTitle,
          sortBy,
          order,
          search,
          isBlocked,
          page, 
          limit
        },
      },
    );
    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await makeRequestSQL.get(
      '/user/me',
    );
    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOnlineUsers = async () => {
  try {
    const response = await makeRequestSQL.get(`/user/online`);
    const decryptedData = await  decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
}

const fetchIPAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    const { ip } = response.data;

    return ip;
  } catch (error) {
    return null;
  }
};


export const loginUser = async credentials => {
  try {
    const ip = await fetchIPAddress();
    const requestData = { ...credentials, ip };

    const response = await makeRequestSQL.post('/user/login', requestData);

    const decryptedData = await decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const logoutUser = async () => {
  try {
    const response = await makeRequestSQL.put('/user/logout');
    clearAuthHeader()
    const decryptedData = await  decryptMiddleware(response.data);
    return {status: response.status, data: decryptedData};
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateUser = async ({id, data}) => {
  try {
    const response = await makeRequestSQL.put(
      `/user/${id}`,
      data,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createUser = async data => {
  try {
    const response = await makeRequestSQL.post('/user/create', data);

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};