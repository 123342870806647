import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchMyDailyStatistics, fetchMySummaryStatistics } from "../../../api/statistic";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import {
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { v4 as uuidv4 } from "uuid";

export const MyStatistics = () => {
  const [sortBy, setSortBy] = useState("order_date");
  const [order, setOrder] = useState("DESC");
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);


  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data: dailyData, isSuccess, refetch } = useQuery(
    ["fetchMyDailyStatistics", { sortBy, order }],
    () =>
      fetchMyDailyStatistics({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  const { data: summaryData } = useQuery(
    ["fetchMySummaryStatistics", { sortBy, order }],
    () =>
      fetchMySummaryStatistics({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );


  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button onClick={() => setIsModalDateOpen(true)} style={{width: '200px'}}>Оберіть період</Button>
      </div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

<Table>
        <Thead>
          <HeadTr>
            <Th onClick={() => handleSortChange("average_total_amount")} style={{width: '70px'}}>
              Середній чек {renderSortIcon("average_total_amount")}
            </Th>
            <Th onClick={() => handleSortChange("total_order_count")} style={{width: '70px'}}>
              Всього замовлень {renderSortIcon("total_order_count")}
            </Th>
            <Th onClick={() => handleSortChange("total_products")} style={{width: '70px'}}>
              Кількість проданих товарів{renderSortIcon("total_products")}
            </Th>
            <Th onClick={() => handleSortChange("total_worked_hours")} style={{width: '70px'}}>
              Кількість робочих годин{renderSortIcon("total_worked_hours")}
            </Th>
            <Th onClick={() => handleSortChange("average_time_per_order")} style={{width: '70px'}}>
              Середній час на 1 апрув{renderSortIcon("average_time_per_order")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")}>
               Викуплених замовлень від всіх оформлених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")} >
              Всього викуплених/невикуплених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("buyout_percentage")}>
              Відсоток викуплених від викуплених/невикуплених{" "}
              {renderSortIcon("buyout_percentage")}
            </Th>
            <Th>
              По статусах
            </Th>
            <Th onClick={() => handleSortChange("total_hourly_salary")} style={{width: '80px'}}>
              Виплата за погодину оплату{renderSortIcon("total_hourly_salary")}
            </Th>
            <Th onClick={() => handleSortChange("total_hourly_salary")} style={{width: '80px'}}>
              Виплата за замовленя{renderSortIcon("total_hourly_salary")}
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!summaryData) ? (
          <tbody>
          <Tr>
            <Td style={{width: '800px'}}>За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз</Td>
          </Tr>
        </tbody>
        ) : (
          <tbody>
            {summaryData && 
                <Tr key={summaryData.user_id}>
                  <Td style={{width: '92px'}}>{summaryData.average_total_amount} грн</Td>
                  <Td style={{width: '92px'}}>{summaryData.total_order_count}</Td>
                  <Td style={{width: '92px'}}>{summaryData.total_products}</Td>
                  <Td style={{width: '92px'}}>{summaryData.total_worked_hours}</Td>
                  <Td style={{width: '92px'}}>{summaryData.average_time_per_order}</Td>
                  <Td>{summaryData.total_buyout_orders}</Td>
                  <Td>{summaryData.total_relevant_orders}</Td>
                  <Td>{summaryData.buyout_specific_percentage}%</Td>
                  <Td>
                      {summaryData?.statuses?.map((status) => {
                        const orders_percentage =
                          Number(
                            (
                              (status.order_count /
                                (summaryData.total_order_count +
                                  summaryData.total_recall_orders)) *
                              100
                            )?.toFixed(2)
                          ) || 0;

                        return (
                          <p key={status.order_status_id}>
                            <span style={{ fontWeight: "bold" }}>
                              {status.status_title}
                            </span>{" "}
                            <br />
                            Кількість -{" "}
                            {status.order_count ? status.order_count : 0} <br />
                            Відсоток - {orders_percentage}%
                          </p>
                        );
                      })}
                    </Td>
                  <Td style={{width: '102px'}}>{summaryData.total_hourly_salary} грн</Td>
                  <Td style={{width: '102px'}}>{summaryData.percent_sum} грн</Td>
                </Tr>

            }
          </tbody>
        )}
      </Table>

      <Table style={{marginTop: '40px'}}>
        <Thead>
          <HeadTr>
            <Th onClick={() => handleSortChange("order_date")} style={{width: '70px'}}>
              Дата {renderSortIcon("order_date")}
            </Th>
            <Th onClick={() => handleSortChange("average_total_amount")} style={{width: '70px'}}>
              Середній чек {renderSortIcon("average_total_amount")}
            </Th>
            <Th onClick={() => handleSortChange("total_order_count")} style={{width: '70px'}}>
              Всього замовлень {renderSortIcon("total_order_count")}
            </Th>
            <Th onClick={() => handleSortChange("total_products")} style={{width: '70px'}}>
              Кількість проданих товарів{renderSortIcon("total_products")}
            </Th>
            <Th onClick={() => handleSortChange("total_worked_hours")} style={{width: '70px'}}>
              Кількість робочих годин{renderSortIcon("total_worked_hours")}
            </Th>
            <Th onClick={() => handleSortChange("average_time_per_order")} style={{width: '70px'}}>
              Середній час на 1 апрув{renderSortIcon("average_time_per_order")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")}>
               Викуплених замовлень від всіх оформлених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")} >
              Всього викуплених/невикуплених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("buyout_percentage")}>
              Відсоток викуплених від викуплених/невикуплених{" "}
              {renderSortIcon("buyout_percentage")}
            </Th>
            <Th>
              По статусах
            </Th>
            <Th onClick={() => handleSortChange("total_hourly_salary")} style={{width: '80px'}}>
              Виплата за погодину оплату{renderSortIcon("total_hourly_salary")}
            </Th>
            <Th onClick={() => handleSortChange("total_hourly_salary")} style={{width: '80px'}}>
              Виплата за замовленя{renderSortIcon("total_hourly_salary")}
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!dailyData || dailyData?.length === 0) ? (
          <tbody>
            <Tr>
              <Td style={{width: '800px'}}>За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз</Td>
            </Tr>
          </tbody>
        ) : (
          <tbody>
            {dailyData && dailyData?.map((item) => {
              const key = uuidv4();
              return (
                <Tr key={key}>
                  <Td style={{width: '92px'}}>{formattedDateToDayMonthYear(item.order_date)}</Td>
                  <Td style={{width: '92px'}}>{Number(item.average_total_amount) ? item.average_total_amount : 0} грн</Td>
                  <Td style={{width: '92px'}}>{item.total_order_count}</Td>
                  <Td style={{width: '92px'}}>{item.total_products}</Td>
                  <Td style={{width: '92px'}}>{item.total_worked_hours}</Td>
                  <Td style={{width: '92px'}}>{item.average_time_per_order}</Td>
                  <Td>{item.total_buyout_orders}</Td>
                  <Td>{item.total_relevant_orders}</Td>
                  <Td>{item.buyout_specific_percentage?.toFixed(2)}%</Td>
                  <Td>
                      {item?.statuses?.map((status) => {
                        const orders_percentage =
                          Number(
                            (
                              (status.order_count /
                                (item.total_order_count +
                                  item.total_recall_orders)) *
                              100
                            )?.toFixed(2)
                          ) || 0;

                        return (
                          <p key={status.order_status_id}>
                            <span style={{ fontWeight: "bold" }}>
                              {status.status_title}
                            </span>{" "}
                            <br />
                            Кількість -{" "}
                            {status.order_count ? status.order_count : 0} <br />
                            Відсоток - {orders_percentage}%
                          </p>
                        );
                      })}
                    </Td>
                  <Td style={{width: '102px'}}>{(item.total_hourly_salary)?.toFixed(0)} грн</Td>
                  <Td style={{width: '102px'}}>{item.percent_sum} грн</Td>
                </Tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </Main>
  );
};
