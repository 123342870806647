import React from 'react';
import { Wrapper } from './EmojiDisplay.styled';
import Lottie from 'react-lottie-player'

export const EmojiDisplay = ({ emojiId }) => {
const emojiUrl = `https://fonts.gstatic.com/s/e/notoemoji/latest/${emojiId}/lottie.json`

    return (
      <Wrapper>
        <Lottie
          loop
          play
          path={emojiUrl}  
          style={{ width: 100}}
        />
      </Wrapper>
    );
  };