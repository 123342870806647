import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchContactsStatistics } from "../../../api/statistic";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { v4 as uuidv4 } from "uuid";

export const ContactsStatistics = () => {
  const [sortBy, setSortBy] = useState("title");
  const [order, setOrder] = useState("ASC");

  const { data, isSuccess, refetch } = useQuery(
    ["fetchContactsStatistics", {sortBy, order }],
    () =>
      fetchContactsStatistics({
        sortBy, 
        order 
      })
  );

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <Main>
            <Table>
        <Thead>
          <HeadTr>                                                                                    
            <Th >
              Всього контактів
            </Th>
            <Th>
              Опрацьовано
            </Th>
            <Th>
              Відсоток опрацьованих контактів
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!data || data?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
                            <Tr >
                  <Td>{data && data?.length > 0 && data[0]?.total_records}</Td>
                  <Td>{data && data?.length > 0 && data[0]?.total_non_specific_status}</Td>
                  <Td>{data && data?.length > 0 && ((data[0]?.total_non_specific_status/data[0]?.total_records)*100).toFixed(2)}%</Td>
                </Tr>
          </tbody>
        )}
      </Table>

      <Table>
        <Thead>
          <HeadTr>                                                                                    
            <Th onClick={() => handleSortChange("title")} style={{width: '250px'}}>
              Назва статусу {renderSortIcon("title")}
            </Th>
            <Th onClick={() => handleSortChange("total_count")}>
              Всього записів в цьому статусі {renderSortIcon("total_count")}
            </Th>
            <Th onClick={() => handleSortChange("total_records")}>
              Всього записів {renderSortIcon("total_records")}
            </Th>
            <Th onClick={() => handleSortChange("total_non_specific_status")}>
            Всього записів в статусах крім "Новий контакт" {renderSortIcon("total_non_specific_status")}
            </Th>
            <Th onClick={() => handleSortChange("percentage_to_all_contacts")}>
              Відсоток від всіх записів {renderSortIcon("percentage_to_all_contacts")}
            </Th>
            <Th onClick={() => handleSortChange("percentage_to_not_new_contacts")}>
            Відсоток від записів крім "Новий контакт" {renderSortIcon("percentage_to_not_new_contacts")}
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!data || data?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            {data && data?.map((item) => {
              const key = uuidv4();
              return (
                <Tr key={key}>
                  <Td  style={{width: '272px'}}>{item.title}</Td>
                  <Td>{item.total_count}</Td>
                  <Td>{item.total_records}</Td>
                  <Td>{item.total_non_specific_status}</Td>
                  <Td>{item.percentage_to_all_contacts}%</Td>
                  <Td>{item.percentage_to_not_new_contacts}%</Td>
                </Tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </Main>
  );
};
