import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchProduct = async ({
  sortBy,
  order,
  searchQuery,
  currentPage,
  pageLimit,
  id,
  category_id,
  is_available
}) => {
  try {
    const response = await makeRequestSQL.get("/products/", {
      params: {
        sortBy,
        order,
        search: searchQuery,
        page: currentPage,
        limit: pageLimit,
        id,
        category_id,
        is_available
      },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateProduct = async (data) => {
  try {
    const response = await makeRequestSQL.put("/products/", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createProduct = async (data) => {
  try {
    const response = await makeRequestSQL.post("/products/", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await makeRequestSQL.delete("/products/", {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchProductCategory = async () => {
  try {
    const response = await makeRequestSQL.get("/products/category");

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
