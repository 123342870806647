import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchOverallSummary } from "../../../api/statistic";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { Button } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const SecondaryOverallSummary = () => {
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data, isSuccess, refetch } = useQuery(["getEmployeeStatistic"], () =>
    fetchOverallSummary({
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    })
  );

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px" }}
        >
          Оберіть період
        </Button>
      </div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>
            <Th style={{ width: "70px" }}>Середній чек</Th>
            <Th style={{ width: "70px" }}>Всього замовлень</Th>
            <Th style={{ width: "70px" }}>Кількість проданих товарів</Th>
            <Th style={{ width: "70px" }}>Кількість робочих годин</Th>
            <Th style={{ width: "70px" }}>Середній час на 1 апрув</Th>
            <Th>Всього викуплених замовлень від всіх оформлених </Th>
            <Th style={{ width: "80px" }}>Відсоток викуплених замовлень </Th>
            <Th>Всього викуплених/невикуплених </Th>
            <Th>Відсоток викуплених від викуплених/невикуплених </Th>
            <Th>По статусах</Th>

            <Th style={{ width: "80px" }}>Рейтинг</Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!data || data?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            <Tr>            
              <Td style={{ width: "92px" }}>
                {data?.average_total_amount} грн
              </Td>
              <Td style={{ width: "92px" }}>{data?.total_order_count}</Td>
              <Td style={{ width: "92px" }}>{data?.total_products}</Td>
              <Td style={{ width: "92px" }}>{data?.total_worked_hours}</Td>
              <Td style={{ width: "92px" }}>{data?.average_time_per_order}</Td>
              <Td>{data?.total_buyout_orders}</Td>
              <Td style={{ width: "102px" }}>
                {data?.buyout_percentage?.toFixed(2)}%
              </Td>
              <Td>{data?.total_relevant_orders}</Td>
              <Td>{data?.buyout_specific_percentage?.toFixed(2)}%</Td>
              <Td>
                {data?.statuses?.map((status) => {
                  const orders_percentage =
                    Number(
                      (
                        (status.order_count /
                          (data.total_order_count + data.total_recall_orders)) *
                        100
                      )?.toFixed(2)
                    ) || 0;

                  return (
                    <p key={status.order_status_id}>
                      <span style={{ fontWeight: "bold" }}>
                        {status.status_title}
                      </span>{" "}
                      <br />
                      Кількість - {status.order_count
                        ? status.order_count
                        : 0}{" "}
                      <br />
                      Відсоток - {orders_percentage}%
                    </p>
                  );
                })}
              </Td>
              <Td style={{ width: "102px" }}>
                {(data?.profit?.toFixed(0) / 100)?.toFixed(2) ? (data?.profit?.toFixed(0) / 100)?.toFixed(2) : 0}
              </Td>
            </Tr>
          </tbody>
        )}
      </Table>
    </Main>
  );
};
