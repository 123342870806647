import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const uploadContacts = async (data) => {
  try {
    const response = await makeRequestSQL.post("/contacts", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchContacts = async ({
  id,
  page,
  limit,
  sortBy,
  order,
  search,
  city,
  status_id,
  employee_id
}) => {
  try {
    const response = await makeRequestSQL.get("/contacts", {
      params: {
        id,
        page,
        limit,
        sortBy,
        order,
        search,
        city,
        status_id,
        employee_id
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const updateContact = async (data) => {
  try {
    const response = await makeRequestSQL.put("/contacts", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createOneContact = async (data) => {
  try {
    const response = await makeRequestSQL.post("/contacts/one-contact", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteContact = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/contacts`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};