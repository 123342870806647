const CryptoJS = require("crypto-js");

const key = process.env.REACT_APP_DECRYPT_KEY;

export const decryptMiddleware = async (encryptedData) => {
  try {
    const bytes = await CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedText = await bytes.toString(CryptoJS.enc.Utf8);
    const decryptedData = await JSON.parse(decryptedText);
    return decryptedData;
  } catch (error) {
    throw new Error('Помилка розшифрування даних');
  }
};

export const encryptMiddleware = async (data) => {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return ciphertext;
}

export const decryptString = async (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    const decryptedData = JSON.parse(decryptedText);
    return decryptedData;
  } catch (error) {
    throw new Error('Помилка розшифрування даних');
  }
};
