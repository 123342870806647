import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { UserContext } from '../Context/UserContext';

export const MyEditor = ({ value, onChange }) => {
  const { language } = useContext(UserContext);
  const key = process.env.REACT_APP_EDITOR_KEY;

  return (
    <Editor
      apiKey={key}
      init={{
        language: language === 'russian' ? 'ru' : 'uk',
        direction: 'ltr',
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
        height: 800
      }}
      initialValue={value}
      onEditorChange={(newContent) => {
        onChange && onChange(newContent);
      }}
    />
  );
}
