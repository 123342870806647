import React from "react";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import { Position } from "../../components/GlobalSettings/Position/Position";
import { RoutePermissions } from "../../components/GlobalSettings/RouteAccess/RouteAccess";
import { MainRoutes } from "../../components/GlobalSettings/MainRoutes/MainRoutes";
import { ScrollToTopButton } from "../../components/ScrollToTopButton/ScrollToTopButton";
import { ContactsStatuses } from "../../components/GlobalSettings/ContactsStatuses/ContactsStatuses";

export const GlobalSettings = () => {
  return (
    <div>
      <SharedLayout />
      <Main>
        <Position />
        <MainRoutes />
        <RoutePermissions />
        <ContactsStatuses />
        <ScrollToTopButton />
      </Main>
    </div>
  );
};
