import styled from '@emotion/styled';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import {
    Td,
    Th,
  } from "../../components/GlobalComponentsAndStyles/GlobalStyles.styled";

export const OnlineTd = styled(Td)`
  width: 252px;
`;

export const OnlineTh = styled(Th)`
  width: 230px;
`;

export const UserFaTimesCircle = styled(FaTimesCircle)`
cursor: pointer;
font-size: 24px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-weight: 700; 
`

export const UserFaMinusCircle = styled(FaCheckCircle)`
cursor: pointer;
font-size: 24px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-weight: 700; 
`