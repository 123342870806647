import React, { useContext } from "react";
import { MainContainer } from "./GlobalStyles.styled";
import { NavContext } from "../Context/NavContext";
import PropTypes from "prop-types";

export const Main = ({ children }) => {
  const { menuVisible } = useContext(NavContext);
  return <MainContainer isVisible={menuVisible}>{children}</MainContainer>;
};

Main.defaultProps = {
  children: null,
};

Main.propTypes = {
  children: PropTypes.node,
};
