import styled from '@emotion/styled';


export const SearchContainer = styled.form`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 3px solid #2f303a;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #000;

  &:focus {
    color: #000;
  }
`;

export const SearchButton = styled.button`
  width: 40px;
  height: 35px;
  background: #2f303a;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus,
  &.active {
    background-color: green;
    border: 2px solid #2f303a;
    transition: background-color 0.4s;
  }
`;
