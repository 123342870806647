import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

export const Item = styled.li`
  margin: 0;
  width: 100%;
  border: 2px solid #eeee40;
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex;

  &:hover,
  &:focus,
  &.active {
    border: 2px solid #2f303a;
  }
`;

export const LinkContainer = styled.div`
  width: ${(props) => (props.accessLevel ? "90%" : "100%")};
  transition: background-color 0.2s;
  background-color: #2f303a;
  color: #f5f7fa;
  border-radius: 5px;

  &:hover,
  &:focus,
  &.active {
    background-color: #eeee40;
  }
`;

export const KnowledgeLink = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  padding-left: 20px;
  margin: -1px;
  font-weight: 700;
  font-size: 18px;
  color: #f5f7fa;
  transition: color 0.2s;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    color: #000000;
  }
`;

export const IconLinkContainer = styled.div`
  display: flex;
  width: 20%;
`;
