import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";


export const createPosition = async (name) => {
  try {
    const response = await makeRequestSQL.post("/settings/position", name);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchPosition = async () => {
  try {
    const response = await makeRequestSQL.get("/settings/position");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const deletePosition = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/settings/position`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updatePosition = async (data) => {
  try {
    const response = await makeRequestSQL.put("/settings/position", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createRoute = async (name) => {
  try {
    const response = await makeRequestSQL.post("/settings/main-route", name);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchRoute = async () => {
  try {
    const response = await makeRequestSQL.get("/settings/main-route");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteRoute = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/settings/main-route`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateRoute = async (data) => {
  try {
    const response = await makeRequestSQL.put("/settings/main-route", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createRouteSecondary= async (name) => {
  try {
    const response = await makeRequestSQL.post("/settings/secondary-route", name);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchRouteSecondary = async () => {
  try {
    const response = await makeRequestSQL.get("/settings/secondary-route");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteRouteSecondary = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/settings/secondary-route`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateRouteSecondary = async (data) => {
  try {
    const response = await makeRequestSQL.put("/settings/secondary-route", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchPermission = async (path) => {
  try {
    const response = await makeRequestSQL.get("/settings/route-permission", {
      params: { path },
    });

    const decryptedData = await decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
    throw error;
  }
};

export const createContactStatuses = async (title) => {
  try {
    const response = await makeRequestSQL.post("/settings/contact-statuses", title);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const getContactStatuses = async () => {
  try {
    const response = await makeRequestSQL.get("/settings/contact-statuses");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const deleteContactStatuses = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/settings/contact-statuses`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateContactStatuses = async (data) => {
  try {
    const response = await makeRequestSQL.put("/settings/contact-statuses", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};