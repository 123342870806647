import styled from "@emotion/styled";
import { Form, Field, ErrorMessage } from "formik";
import { Button } from "../../components/GlobalComponentsAndStyles/GlobalStyles.styled";

export const LoginMain = styled.main`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
`;

export const LoginContainer = styled.div`
  max-width: 290px;
  padding: 0;
  margin: 0 auto;
`;

export const LoginOuter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #2f303a;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.04);
  margin-bottom: 2rem;
  border-radius: 10px;
  padding: 10px;
`;

export const LoginCardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
  background-color: #2f303a;
`;

export const LoginLogoText = styled.p`
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.625;
  color: #435a6b;
`;

export const LoginLogoImage = styled.img`
  width: 210px;
  height: 80px;
  border-style: none;
`;

export const InfoForm = styled(Form)``;

export const Input = styled(Field)`
  width: 70%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;

export const InputsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
`;

export const Error = styled(ErrorMessage)`
  color: red;
  font-style: italic;
  text-align: center;
  position: absolute;
  top: -20px;
  left: -30px;
  text-wrap: nowrap;
`;

export const ButtonContainer = styled.div`
  display: block;
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-bottom: 10px;
`;

export const InputIconContainer = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  background-color: #f5f7fa;
`;

export const ErrorContainer = styled.div`
  color: red;
`;

export const EyeIconContainer = styled.div`
  position: absolute;
  right: 10%;
  top: 20%;
  width: 6%;
  height: 100%;
`;

export const LoginFormButton = styled(Button)`
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
