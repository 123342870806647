import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createPosition,
  fetchPosition,
  deletePosition,
  updatePosition,
} from "../../../api/settings";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  TrashIconButton
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  PositionTh,
  PositionTd,
  IconLinkContainer,
} from "./Position.styled";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";

export const Position = () => {
  const [editingPositionId, setEditingPositionId] = useState(null);
  const [addingNewPosition, setAddingNewPosition] = useState(false);
  const [newPositionName, setNewPositionName] = useState("");
  const [editingPositionName, setEditingPositionName] = useState("");

  const { data: positionData, refetch } = useQuery("position", fetchPosition);

  const handleEditPosition = (positionId, positionName) => {
    setEditingPositionId(positionId);
    setEditingPositionName(positionName);
  };

  const handleSaveChanges = async (id, name) => {
    const data = {
      id,
      name,
    };

    const response = await updatePosition(data);

    if (response?.status === 201) {
      toast.success(response?.data?.message);
      setNewPositionName("");
      handleCancelEdit();
      refetch();
    }
  };

  const handleCancelEdit = () => {
    setEditingPositionId(null);
    setEditingPositionName("");
  };

  const handleDeletePosition = async (positionId) => {
    const response = await deletePosition(positionId);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      setNewPositionName("");
      handleCancelEdit();
      refetch();
    }
  };

  const handleAddNewPosition = () => {
    setAddingNewPosition(true);
  };

  const handleSaveNewPosition = async () => {
    const response = await createPosition({ name: newPositionName });

    if (response.status === 201) {
      toast.success(response?.data?.message);
      setNewPositionName("");
      handleCancelEdit();
      setAddingNewPosition(false);
      refetch();
    }
  };

  return (
    <div>
      <Table>
        <Thead>
          <HeadTr>
            <PositionTh>Назва посади</PositionTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          {positionData ? (
            <React.Fragment>
              {positionData?.map((position) => (
                <Tr key={position.id}>
                  <PositionTd>
                    {editingPositionId === position.id ? (
                      <Input
                        type="text"
                        value={editingPositionName}
                        onChange={(e) => setEditingPositionName(e.target.value)}
                      />
                    ) : (
                      <>{position.name}</>
                    )}
                  </PositionTd>
                  <Td>
                    {editingPositionId === position.id ? (
                      <IconLinkContainer>
                        <IconButton
                          onClick={() =>
                            handleSaveChanges(position.id, editingPositionName)
                          }
                        >
                          <FaSave size="20px" />
                        </IconButton>
                        <TrashIconButton onClick={handleCancelEdit}>
                          <MdCancel size="25px" />
                        </TrashIconButton>
                      </IconLinkContainer>
                    ) : (
                      <IconLinkContainer>
                        <IconButton
                          onClick={() =>
                            handleEditPosition(position.id, position.name)
                          }
                        >
                          <FaEdit size="20px" />
                        </IconButton>
                        <TrashIconButton
                          onClick={() => handleDeletePosition(position.id)}
                        >
                          <FaTrash size="20px" />
                        </TrashIconButton>
                      </IconLinkContainer>
                    )}
                  </Td>
                </Tr>
              ))}
              <Tr>
                {addingNewPosition ? (
                  <>
                    <PositionTd>
                      <Input
                        type="text"
                        value={newPositionName}
                        onChange={(e) => setNewPositionName(e.target.value)}
                      />
                    </PositionTd>
                    <Td>
                      <IconLinkContainer>
                        <IconButton onClick={handleSaveNewPosition}>
                          <FaSave size="20px" />
                        </IconButton>
                        <TrashIconButton
                          onClick={() => setAddingNewPosition(false)}
                        >
                          <MdCancel size="25px" />
                        </TrashIconButton>
                      </IconLinkContainer>
                    </Td>
                  </>
                ) : (
                  <>
                    <PositionTd></PositionTd>
                    <Td>
                      <IconButton
                        onClick={handleAddNewPosition}
                        style={{ width: "75%" }}
                      >
                        <MdOutlineAddCircle size="25px" />
                      </IconButton>
                    </Td>
                  </>
                )}
              </Tr>
            </React.Fragment>
          ) : (
            <Tr>
              <Td
                colSpan={2}
                style={{
                  width: "400px",
                  textAlign: "start",
                  paddingLeft: "50px",
                }}
              >
                Нажаль поки немає даних
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
