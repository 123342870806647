import styled from '@emotion/styled';
import {
    Td,
    Th,
  } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const NewsSmallTh = styled(Th)`
width: 146px;
`

export const NewsLargeTh = styled(Th)`
width: 546px;
`

export const NewsSmallTd = styled(Td)`
width: 168px;
`

export const NewsLargeTd = styled(Td)`
width: 558px;
padding: 10px;
`

export const IconLinkContainer = styled.div`
display: flex;
width: 100%;
`

export const NewsText = styled.p`
margin: 0 0 5px 0;
`