import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import {
  fetchKnowledgeBase,
  deleteKnowledgeBase,
} from "../../../api/knowledgeBase";
import { NavContainer, IconLinkContainer } from "./KnowledgeBaseDetails.styled";
import {
  IconButton,
  TrashIconButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { BackLink } from "../../BackLink/BackLink";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import css from "./KnowledgeBaseDetails.module.css";
import { ScrollToTopButton } from "../../ScrollToTopButton/ScrollToTopButton";

export const KnowledgeBaseDetails = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isPrimary, setIsPrimary] = useState(false);
  const [isList, setIsList] = useState(false);
  const [initialValue, setInitialValue] = useState({
    id: data?.id || "",
    title: data?.title || "",
    description: data?.description || "",
  });
  const [selectedId, setSelectedId] = useState();
  const path = location?.pathname;

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length >= 3) {
        const getId = parts[3];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkPrimary = `/${parts[2]}` === "/primary-list";
        const checkList = `/${parts[2]}` === "/list";

        if (checkPrimary) {
          setIsPrimary(true);
        }

        if (checkList) {
          setIsList(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    if (!isPrimary && !isList) {
      return;
    }

    const fetchProductData = async () => {
      const response = await fetchKnowledgeBase({ id: selectedId });
      console.log(response);
      if (response?.data?.length > 0) {
        const fetchedData = response?.data;
        setInitialValue({
          ...fetchedData[0],
        });
      }
    };

    fetchProductData();
  }, [location, isPrimary, isList, selectedId]);

  const handleDelete = async (id) => {
    const response = await deleteKnowledgeBase(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return navigate("/knowledge-base/primary-list");
    }
  };

  return (
    <div>
      <Main>
        <NavContainer>
          {isPrimary && <BackLink to="/knowledge-base/primary-list" />}
          {isList && <BackLink to="/knowledge-base/list" />}
          {isPrimary && (
            <IconLinkContainer style={{ marginLeft: "20px" }}>
              <IconButton to={`/knowledge-base/change/${initialValue?.id}`}>
                <FaEdit size={"24px"} />
              </IconButton>
              <TrashIconButton
                onClick={() => handleDelete(initialValue?.id)}
                style={{ marginLeft: "20px" }}
              >
                <FaTrash size={"24px"} />
              </TrashIconButton>
            </IconLinkContainer>
          )}
        </NavContainer>
        {initialValue && (
          <div style={{ userSelect: "none" }}>
            <h2>{initialValue.title}</h2>
            <div
              className={css.knowledgeContent}
              dangerouslySetInnerHTML={{
                __html: initialValue?.description,
              }}
            />
          </div>
        )}
        <ScrollToTopButton />
      </Main>
    </div>
  );
};
